import MojolockBase from './mojolock_base';

class MojolockG4Black extends MojolockBase {
  #price;

  constructor(account) {
    super();
    this.#price = account.mojolock_g4_black_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {
    const el = document.querySelector('strong#fixed_mojolock_price');
    if (el) {
      el.innerText = '$' + this.#price;
    }
  }
}

export default MojolockG4Black;
