import LayoutUtils from '../../../utils/layout_utils';
import QuestionnaireUtils from '../utils';

class Dropdown {

  #questionInfo;

  endsWithHut(str) {
    return !!str.match(/\^\s*$/);
  }

  lastVariantEndsWithHash(arr) {
    return !!arr[arr.length - 1].match(/\#\s*$/);
  }

  createAdditionalQuestion(specialword, elem){
    let questionDiv = document.querySelector('#additional_question').cloneNode(true);
    let addit_type = 'CUSTOM';

    const wrapper = document.querySelector('#questionnaire_holder');

    questionDiv.setAttribute('id' , 'aq_' + wrapper.dataset.aqIndx);
    questionDiv.querySelector('[data-binding="question_type"]').value = addit_type;
    questionDiv.querySelector('[name="question[][childvariant]"]').value = specialword;

    let parent_id = elem.querySelector('[name="question[][id]"]').value;
    let is_child = elem.querySelector('[name="question[][parent_text]"]').value;

    questionDiv.querySelector('[name="question[][parent_id]"]').value = parent_id;
    if(is_child){
      questionDiv.querySelector('[name="question[][is_child]"]').value = is_child;
    }

    let text = specialword ? 'Add question that will display when the option ' + specialword +  ' is selected' : 'Add question that will display when the last option is selected';
    let input = questionDiv.querySelector('[data-binding="text"]');
    if (input.parentElement.querySelector('small')) {
      input.parentElement.querySelector('small').remove();
    }
    input.insertAdjacentHTML('afterend', '<div><small>' + text + '</small></div>');
    questionDiv.style.display = 'block';

    wrapper.setAttribute('data-aq-indx', wrapper.dataset.aqIndx++);
    return questionDiv;
  }

  question_child(obj,selector) {
    return obj.closest('.questionbox').querySelector(selector);
  }

  init(wrapper, _data, data) {
    this.#questionInfo = data?.questionInfo;
   [...wrapper.querySelector('.custom_dropdown_options_select').selectedOptions].forEach(
      (el) => {
        if (el.value == 'simple dropdown') {
          let dropdown = el.parentNode;
          let input_box =  this.question_child(dropdown,'input[name="question[][variants]"]');
          //check if restricted option present
          if (/( *# *$)|( *# *;)/.test(input_box.value)) {
            [...dropdown.options].find(o => o.innerText == 'restrict scheduling')?.setAttribute('selected', true);
          }
        }
      }
    );

    this.bindEvents(wrapper);
  }

  bindEvents(questionDiv) {
    const lastVariantEndsWithHash = this.lastVariantEndsWithHash;
    const createAdditionalQuestion = this.createAdditionalQuestion;
    const endsWithHut = this.endsWithHut;

    questionDiv.querySelector('[name="question[][custom_dropdown_type]"]').addEventListener('change', function(e) {
      const that = e.target;
      if (e.target.value == 'ask more details' || e.target.value == 'ask more & restrict') {
        let checkedvariants = that.closest('.questionbox').querySelector('input[name="question[][variants]"]').value.split(';');
        let placeholder_base = that.closest('.questionbox').querySelector('input[name="question[][variants]"]');
        let placeholderText;

        if (that.value =='ask more details') {
          placeholderText = 'To ask for more details, end the answer with ^';
        } else if (that.value == 'ask more & restrict') {
          placeholderText = 'To restrict, end the answer with #. To ask more, end with ^';
        }

        if (placeholder_base.parentElement.querySelector('small')) {
          placeholder_base.parentElement.querySelector('small').remove();
        }
        placeholder_base.insertAdjacentHTML('afterend', '<small>' + placeholderText + '</small>');

        let additionalQuestionsAlreadyAdded = that.closest('.questionbox').querySelectorAll('.dropdown_additional_questions .additional-question').length;
        if (additionalQuestionsAlreadyAdded) { return; }
        let count = 0;
        checkedvariants.forEach(function(el){
          if(endsWithHut(el)){
            count = count + 1;
            let question = createAdditionalQuestion(el, that.closest('.questionbox'));
            that.closest('.questionbox').querySelector('.dropdown_additional_questions').append(question);
          }
        });
        if(count == 0 && !lastVariantEndsWithHash(checkedvariants)) {
          let question = createAdditionalQuestion(checkedvariants[checkedvariants.length-1], that.closest('.questionbox'));
          that.closest('.questionbox').querySelector('.dropdown_additional_questions').append(question);
        }
      } else {
        let question_input =  that.closest('.questionbox').querySelector('input[name="question[][variants]"]');

        if (question_input.parentElement.querySelector('small')) {
          question_input.parentElement.querySelector('small').remove();
        }

        if (that.value =='restrict scheduling') {
          question_input.insertAdjacentHTML('afterend', '<small>To restrict scheduling based on a specific answer, end the answer with #</small>');
        } else {
          question_input.insertAdjacentHTML('afterend', '<small>Add dropdown answer options -- separated by semi-colons</small>');
        }

        that.closest('.questionbox').querySelector('.dropdown_additional_questions').innerHTML = '';
        let id = that.closest('.questionbox').querySelector('[name="question[][id]"]').value;
        if(id.match(/[0-9]/)){
          QuestionnaireUtils.eraseAllAdditionalQuestion(id);
        }
      }
    });

    questionDiv.querySelector('input[name="question[][variants]"]').addEventListener('focusout', function(e) {
      let that = e.target;
      let value = that.closest('.questionbox').querySelector('[name="question[][custom_dropdown_type]"]').value;

      function assign_childvar(elem, value){
        elem.querySelector('[name="question[][childvariant]"]').value = value;
        elem.querySelector('[name="question[][text]"]').setAttribute('placeholder', 'Add question that will display when the option ' + value +  ' is selected.');
      }

      function full_assign(elem, value){
        assign_childvar(elem, value);
        elem.setAttribute('data-text', value);
      }

      function variant_index(variants, str) {
        let result = -1;
        variants.forEach(function (el) {
          if (el.replace(/\^$/, '') == str.replace(/\^$/, '')) {
            result = el;
            return;
          }
        });
        return result;
      }

      if (value == 'ask more details' || value == 'ask more & restrict') {
        let checkedvariants = that.value.split(';');
        let withhut = [];
        checkedvariants.forEach(function(el) {
          if (endsWithHut(el)) {
            withhut.push(el);
          }
        });
        if (withhut.length === 0 && !lastVariantEndsWithHash(checkedvariants)) {
          if (checkedvariants[checkedvariants.length - 1]) {
            withhut.push(checkedvariants[checkedvariants.length - 1]);
          }
        }

        let persisted_questions = that.closest('.questionbox').querySelectorAll('.persisted_subquestion');
        if(persisted_questions.length == 1 && withhut.length == 1) {
          full_assign(persisted_questions[0], withhut[0]);
        }

        persisted_questions.forEach(function(el){
          let current_var = el.getAttribute('data-text'),
            index = variant_index(withhut, current_var);
          if(index == -1) {
            let id = el.getAttribute('data-id');
            QuestionnaireUtils.eraseAdditionalQuestion(id);
            el.remove();
          } else {
            if (withhut.indexOf(current_var) == -1){
              full_assign(el, withhut[index]);
            }
            withhut.splice(index, 1);
          }
        });

        let old_questions = that.closest('.questionbox').querySelectorAll('.dropdown_additional_questions > div.new_question');

        if(old_questions.length == 1 && withhut.length == 1) {
          assign_childvar(old_questions[0], withhut[0]);
        }

        old_questions.forEach(function(el){
          let text = el.querySelector('[name="question[][childvariant]"]').value,
            index = variant_index(withhut, text);
          if(index == -1) {
            that.remove();
          }
          else
          {
            if (withhut.indexOf(text) == -1) {
              assign_childvar(el, withhut[index]);
            }
            withhut.splice(index, 1);
          }
        });

        withhut.forEach(function(el){
          let question = createAdditionalQuestion(withhut[el], that.closest('.questionbox'));
          that.closest('.questionbox').querySelector('.dropdown_additional_questions').insertAdjacentHTML('beforeend', question);
        });
      }
    });
  }

  getHtml({customDropdownQuestionOptionsSelect}, questionDiv) {
    const selectHtml = this.#questionInfo?.customDropdownOptions ? JSON.parse(this.#questionInfo.customDropdownOptions) : JSON.parse(customDropdownQuestionOptionsSelect);
    LayoutUtils.replaceWith(questionDiv.querySelector('[data-binding="text"]'), '<span class="system_question"><input type="text" class="text-field questionaire" name="question[][text]" value="" /><input placeholder="Add dropdown answer options -- separated by semi-colons." type="text" class="text-field questionaire" name="question[][variants]" value="" /> </span>');
    questionDiv.querySelector('a[data-binding="delete_link"]').insertAdjacentHTML('afterend', `<small class='additional_dropdown_checkbox'>${selectHtml}</small>`);

    //questionDiv.querySelector('#question__custom_dropdown_type').value = this.#questionInfo.selectedType;
    return questionDiv;
  }

  isSingleQuestion() {
    return false;
  }
}

window.Dropdown = Dropdown;
