class DocumentVerification {
  #prevQuestionDocumentAutoApprovalPolicyValue;

  getHtml({defaultDocumentName, documentVerificationHtml}, questionDiv) {
    var question_text = 'We require a photo of your ' + defaultDocumentName + ' to verify your identity.';
    const text = questionDiv.querySelector('[data-binding="text"]');
    text.value = question_text;

    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);

    text.insertAdjacentHTML('afterend', JSON.parse(documentVerificationHtml));
    const answerRequired = questionDiv.querySelector('[data-binding="answer_required"]');
    answerRequired.value = 'true';
    answerRequired.setAttribute('disabled', 'disabled');

    // Trigger dynamic JS behavior
    questionDiv.querySelector('select[name="question[][document_auto_approval_policy]"]').dispatchEvent(new Event('change'));
    questionDiv.querySelector('select[name="question[][document_type]"]').dispatchEvent(new Event('change'));

    return questionDiv;
  }

  showDocumentAutoApprovalDialog(newValue, questionDiv) {
    let content = document.querySelector('#document-auto-approval-warning').innerHTML;

    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content: content,
      hideCloseButton: true,
      onComplete: () => {
        document.querySelector('.js-document-auto-approval-popup-cancel').addEventListener('click', () => {
          questionDiv.querySelector('select[name="question[][document_auto_approval_policy]"]').value =
            this.#prevQuestionDocumentAutoApprovalPolicyValue;
          document.dispatchEvent(new CustomEvent('sm-modal:close'));
        });
        document.querySelector('.js-document-auto-approval-popup-ok').addEventListener('click', () => {
          this.#prevQuestionDocumentAutoApprovalPolicyValue = newValue;
          document.dispatchEvent(new CustomEvent('sm-modal:close'));
        });
      }}}));
  }

  bindDocumentTypeChange(questionDiv, data) {
    questionDiv.querySelector('select[name="question[][document_type]"]').addEventListener('change', function() {
      var newValue = this.value;

      let autoApprovalOptionToHideForOtherDocuments = questionDiv.
        querySelector('select[name="question[][document_auto_approval_policy]"]').
        querySelector(`option[value=${data.autoAproveWhenAutoVerified}]`);

      let alwaysApproveOption = questionDiv.
        querySelector('select[name="question[][document_auto_approval_policy]"]').
        querySelector(`option[value=${data.alwaysAutoApprovePolicy}]`);

      let alwaysApproveOptionNameForIdentityDocument = 'auto-approved even when auto-verification fails';
      let alwaysApproveOptionNameForOtherDocuments = 'auto-approved with NO computer nor human review';

      let autoApprovalInput = questionDiv.querySelector('select[name="question[][document_auto_approval_policy]"]');

      if (newValue == data.identityDocument) {
        LayoutUtils.show(autoApprovalOptionToHideForOtherDocuments);
        // Workaround to hide/show select option for Safari
        // See https://stackoverflow.com/questions/36066953/css-hide-options-from-select-menu-on-iphone-safari
        if ((autoApprovalOptionToHideForOtherDocuments.parentElement.tagName == 'SPAN')) {
          document.querySelector(autoApprovalOptionToHideForOtherDocuments).unwrap();
        }
        alwaysApproveOption.innerHTML = alwaysApproveOptionNameForIdentityDocument;
      } else {
        LayoutUtils.hide(autoApprovalOptionToHideForOtherDocuments);
        // Workaround to hide/show select option for Safari
        if (!(autoApprovalOptionToHideForOtherDocuments.parentElement.tagName == 'SPAN')) {
          const wrapper = document.createElement('span');
          autoApprovalOptionToHideForOtherDocuments.before(wrapper);
          wrapper.append(autoApprovalOptionToHideForOtherDocuments);
        }
        alwaysApproveOption.innerHTML = alwaysApproveOptionNameForOtherDocuments;
        if (autoApprovalInput.value == data.autoAproveWhenAutoVerified) {
          autoApprovalInput.value = data.neverAutoApprovePolicy;
        }
      }
    });
    questionDiv.querySelector('select[name="question[][document_type]"]').dispatchEvent(new Event('change'));
  }

  init(wrapper, data) {
    this.bindEvents(wrapper, data);
  }

  bindEvents(questionDiv, {neverAutoApprovePolicy, alwaysAutoApprovePolicy, identityDocument, autoAproveWhenAutoVerified}) {
    this.#prevQuestionDocumentAutoApprovalPolicyValue = questionDiv.querySelector('select[name="question[][document_auto_approval_policy]"]').value;
    const answRequired = questionDiv.querySelector('[data-binding="answer_required"]');
    answRequired.value = 'true';
    answRequired.setAttribute('disabled', 'disabled');
    questionDiv.querySelector('select[name="question[][document_auto_approval_policy]"]').addEventListener('change', (e) => {
      var newValue = e.target.value;

      if (!this.#prevQuestionDocumentAutoApprovalPolicyValue) {
        this.#prevQuestionDocumentAutoApprovalPolicyValue = neverAutoApprovePolicy;
      }

      let documentType = questionDiv.querySelector('select[name="question[][document_type]"]').value;

      if (newValue == alwaysAutoApprovePolicy && documentType == identityDocument) {
        this.showDocumentAutoApprovalDialog(newValue, questionDiv);
      } else {
        this.#prevQuestionDocumentAutoApprovalPolicyValue = newValue;
      }
      return true;
    });

    this.bindDocumentTypeChange(questionDiv, {neverAutoApprovePolicy, alwaysAutoApprovePolicy, identityDocument, autoAproveWhenAutoVerified});
  }

  isSingleQuestion() {
    return true;
  }
}

window.DocumentVerification = DocumentVerification;
