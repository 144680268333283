class QuestionnaireUtils {
  static removeQuestions(url) {
    fetch(url, {method: 'DELETE', headers: {
      'X-CSRF-Token': FetchUtils.CSRFToken(),
      'Content-Type': 'application/json; charset=utf-8'
    }}).then(FetchUtils.checkResponseStatus)
    .then((resp) => {
      return resp.json();
    }).then(json => {
      document.querySelector(`.question_${json.id}`).closest('.question-item').remove();
      LayoutUtils.show(document.querySelector(`.edit-questions-add[rel="${json.question_type}"]`));
      if (json.unlimited) {
        document.querySelector('.question-add-buttons-container').classList.remove('hidden-important');
      }
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }
  static eraseAllAdditionalQuestion(parent_id){
    const url = '/accounts/' + parent_id + '/questions/' + parent_id + '/destroy_child';
    fetch(url, {method: 'DELETE', headers: {
      'X-CSRF-Token': FetchUtils.CSRFToken(),
      'Content-Type': 'application/json; charset=utf-8'
    }}).then(FetchUtils.checkResponseStatus).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  static eraseAdditionalQuestion(id){
    const url = '/accounts/' + id + '/questions/' + id;
    fetch(url, {method: 'DELETE', headers: {
      'X-CSRF-Token': FetchUtils.CSRFToken(),
      'Content-Type': 'application/json; charset=utf-8'
    }}).then(FetchUtils.checkResponseStatus).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }
}

export default QuestionnaireUtils;
