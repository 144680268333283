import TimePicker from './time_picker';

class ShowingsForm {

  updateListing(data) {
    const timeZoneNote = document.querySelector('#time_zone_note_wrap');
    if (timeZoneNote){
      timeZoneNote.innerHTML = data.time_zone_note_html;
    }

    const picker = document.querySelector('.time_picker');
    if (picker){
      picker.innerHTML = data.time_picker_html;
    }
    document.dispatchEvent(new CustomEvent('listing:changed'));
    const smSelect = document.querySelector('#showing_team_member_id');
    if (smSelect) {
      smSelect.innerHTML = data.team_members_html;
    }
    flatpickr('#custom_showtime_date', {dateFormat: 'M j, Y'});
    const questionWrapper = document.querySelector('#questions_wrapper');
    if (questionWrapper) {
      questionWrapper.innerHTML = data.questions_wrapper_html;
    }

    const confirmation = document.querySelector('#confirmation_wrapper');
    if (confirmation) {
      confirmation.innerHTML = data.confirmation_html;
    }

    // eslint-disable-next-line
    showingFormObject.setDefault();

    document.dispatchEvent(new CustomEvent('html-updated', {detail: {keepOpened: true, container: '.showing-popup-wrapper'}}));
    prospectVerificationMethod.init();

    const acceptBox = document.querySelector('div#accept_box');
    if (JSON.parse(data.listing_accept_enabled)) {
      acceptBox?.setAttribute('data-accept-enable', 'true');
      LayoutUtils.show(acceptBox);
    } else {
      acceptBox?.setAttribute('data-accept-enable', 'false');
      LayoutUtils.hide(acceptBox);
    }

    if (!JSON.parse(data.showing_on_lockbox)) {
      DashboardUtils.hideLockboxOnlyElements();
    }

    if (JSON.parse(data.has_right_pannel)) {
      document.querySelector('.js-separator').classList.remove('hidden');
      document.querySelector('.js-showing-popup-wrapper').classList.remove('without-right-content');
    } else {
      document.querySelector('.js-separator').classList.add('hidden');
      document.querySelector('.js-showing-popup-wrapper').classList.add('without-right-content');
    }
  }

  bindEvents() {
    document.querySelectorAll('.js-showing-form-toggle-panel').forEach(link => {
      link.addEventListener('click', () => {
        LayoutUtils.toggle(document.querySelectorAll('.showing-panel'));
        document.dispatchEvent(new CustomEvent('sm-modal:update-height'));
      });
    });

    document.querySelector('#save_as_lead')?.addEventListener('click', function(e){
      e.preventDefault();
      document.querySelector('form#showing_form')
        .insertAdjacentHTML('beforeend', '<input type="hidden" name="is_lead" value="true">');

      document.querySelector('#showing_showtime').value = '';
      document.querySelector('#showing_lockbox_showtime').value = '';
      Rails.fire(document.querySelector('form#showing_form'), 'submit');
      [...document.querySelectorAll('form#showing_form input')].at(-1).remove();
      return false;
    });

    document.querySelector('#showing_submit')?.addEventListener('click', function(e) {
      if (e.target.getAttribute('disabled')) return;

      if(document.querySelector('#showing_on_lockbox').value == 'true') {
        document.querySelector('.question.only_for_lockbox')?.querySelectorAll('input, textarea, select').forEach((el) => {
          el.setAttribute('disabled', false);
        });
      }

      Rails.fire(e.target.closest('form#showing_form'), 'submit');
    });

    ['ajax:send', 'ajaxSend'].forEach((evt) => {
      document.addEventListener(evt, () => {
        document.querySelector('#showing_submit')?.setAttribute('disabled', 'disabled');
      });
    });

    ['ajax:complete', 'ajaxComplete'].forEach((evt) => {
      document.querySelector('#showing_form').addEventListener(evt, () => {
        document.querySelector('#showing_submit')?.removeAttribute('disabled');
      });
    });

    document.querySelector('#showing_listing_id').addEventListener('change', (e) => {
      const val = e.target.value;
      if (val !== '') {
        document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:beforeSend'));

        let params = {
          'showing[listing_id]': +val,
          'id': document.querySelector('#showing_id').value,
          team_member_id: +document.querySelector('#showing_team_member_id')?.value,
          date: document.querySelector('#custom_showtime_date')?.value,
          time: document.querySelector('#custom_showtime_time')?.value
        };

        fetch('/on_change_listing?' + new URLSearchParams(params), {method: 'POST', headers: {
          'x-csrf-token': FetchUtils.CSRFToken()
        },body: JSON.stringify(params)}).then(FetchUtils.checkResponseStatus).then((resp) => {
          return resp.json();
        }).then((data) => {
          DashboardUtils.checkShowtime(data);
          this.updateListing(data);
          document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:complete'));
          document.querySelector('#showing_listing_id').dispatchEvent(new CustomEvent('listing_changed'));
          document.dispatchEvent(new CustomEvent('showtime-data-updated'));
        }).catch((err) => {
          document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:complete'));
          FetchUtils.handleResponseError(err);
        });
      }
    });

    document.querySelector('#show_all_listings_link')?.addEventListener('click', function(e) {
      e.preventDefault();
      if (e.target.classList.contains('active')) {
        return false;
      }

      document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:beforeSend'));

      let params = JSON.stringify({
        'showing[listing_id]': document.querySelector('#showing_listing_id').value,
        'id': document.querySelector('#showing_id').value
      });

      document.querySelector('#showtime_type').value = 'custom';
      document.querySelector('#showtime_type').dispatchEvent(new Event('change'));
      fetch('/all_listings?' + params, {method: 'POST', headers: {
          'X-CSRF-Token': FetchUtils.CSRFToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }, body: JSON.stringify(params)}).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          flatpickr('#custom_showtime_date', {dateFormat: 'M j, Y'});
          document.querySelector('#showing_listing_id').innerHTML = json.html;
          document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:complete'));
          document.querySelector('#showing_listing_id').dispatchEvent(new CustomEvent('listing_changed'));
          document.querySelector('#show_all_listings_link')?.classList.add('active');
        }).catch((err) => {
          document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:complete'));
          FetchUtils.handleResponseError(err);
        });
      return false;
    });
  }

  switchShowtimeTypeForSurge() {
    const tmSelect = document.querySelector('#showing_team_member_id');
    if (tmSelect && tmSelect.selectedOptions && tmSelect.selectedOptions[0]?.innerText.indexOf('(Surge Agent)') >= 0) {
      document.querySelector('#showtime_type option[value="available"]').remove();
      document.querySelector('#showtime_type').value = 'custom';
      document.querySelector('#showtime_type').dispatchEvent(new Event('change'));
      return true;
    } else {
      return false;
    }
  }

  switchShowingTeamMember() {
    const self = this;
    const showingForm = document.querySelector('#showing_form');
    if (!this.switchShowtimeTypeForSurge()) {
      // Get agent's available showtimes
      showingForm?.dispatchEvent(new CustomEvent('ajax:beforeSend'));

      let params = {
        showing_id: this.getParam('showingId'),
        'showing[listing_id]': document.querySelector('#showing_listing_id').value,
        team_member_id: document.querySelector('#showing_team_member_id')?.value,
        skip_on_lockbox: 'true',
        on_lockbox: document.querySelector('#showing_on_lockbox').value,
        custom_duration: document.querySelector('#custom_showtime_duration').value,
        custom_showtime: document.querySelector('#showing_showtime').value
      };

      fetch('/show_times?' + new URLSearchParams(params)).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.text();
        }).then(data => {
          document.querySelector('.time_picker').innerHTML = data;
          new TimePicker().init();
          showingForm?.dispatchEvent(new CustomEvent('ajax:complete'));

          const showtime = document.querySelector('#shown-showtime').value;
          const onLockboxParam = self.getParam('showingOnLockbox');
          const onLockbox = onLockboxParam !== '' ? JSON.parse(onLockboxParam) : false;

          if (!onLockbox) {
            const picker = document.querySelector('#available_showtimes');
            const setShowtime = !TimepickerBuilder.isSuitableTime(picker, showtime);
            if (picker) {
              picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', {detail: {showtime}}));
            }

            if (setShowtime) {
              document.querySelectorAll('#shown-showtime, #showing_showtime').forEach((el) => {
                el.value = '';
              });
            }
          }

          document.dispatchEvent(new CustomEvent('showtime-data-updated'));
        }).catch((err) => {
          FetchUtils.handleResponseError(err);
        });
    }
  }

  getParam(param) {
    const popup = document.querySelector('.js-showing-popup-wrapper');
    return popup.dataset[param];
  }

  initLeadDistributionCalendar(loadShowtimes = false) {
    document.querySelector('#showing_team_member_id')?.addEventListener('change', () => this.switchShowingTeamMember());
    if (loadShowtimes) {
      this.switchShowingTeamMember();
    } else {
      this.switchShowtimeTypeForSurge();
    }

    if (JSON.parse(this.getParam('canEditCustomShowtime'))) {
      let switch_custom_showtime = function() {
        const showtime_type_options = document.querySelectorAll('#showtime_type option');
        const showtime_types = [...showtime_type_options].map(el => el.value);
        if(document.querySelector('#showing_team_member_id')?.value !== '') {
          // Team member
          if (document.querySelector('#show_all_listings_link')?.classList.contains('active')
            && showtime_types.find((el) => el === 'custom')) {
            document.querySelector('#showtime_type').value = 'custom';
            document.querySelector('#showtime_type').dispatchEvent(new Event('change'));
          }
        } else {
          // Auto-assign
          document.querySelectorAll('#custom_showtime_date, #custom_showtime_time').forEach((el) => {
            el.value = '';
          });
          if (document.querySelector('#show_all_listings_link')?.classList.contains('active')
            && showtime_types.find((el) => el === 'custom')) {
            document.querySelector('#showtime_type').value = 'custom';
            document.querySelector('#showtime_type').dispatchEvent(new Event('change'));
          } else if (showtime_types.find((el) => el === 'available')) {
            document.querySelector('#showtime_type').value = 'available';
            document.querySelector('#showtime_type').dispatchEvent(new Event('change'));
          }
        }
      };

      document.querySelector('#showing_listing_id').addEventListener('listing_changed', switch_custom_showtime);
      document.querySelector('#showing_team_member_id')?.addEventListener('change', function() {
        switch_custom_showtime();
      });

      document.querySelector('#showing_form').addEventListener('ajax:complete', function() {
        switch_custom_showtime();
      });

      document.querySelector('#showing_form').addEventListener('ajax:complete', function() {
        switch_custom_showtime();
      });

      switch_custom_showtime();
    }
  }

  initTwilio() {
    function call(phoneNumber) {
      Twilio.Device.connect({ 'PhoneNumber': phoneNumber });
    }

    function hangup() {
      Twilio.Device.disconnectAll();
    }

    if (typeof(Twilio) === 'function') {
      Twilio.Device.setup(this.getParam('capabilityToken'));

      Twilio.Device.on('ready', function () {
        console.log('Ready');
      });

      Twilio.Device.on('connect', function () {
        console.log('Successfully established call');
      });

      Twilio.Device.on('error', function (error) {
        console.log('Error: ' + error.message);
      });

      Twilio.Device.on('disconnect', function () {
        document.querySelector('#hangup_button').style.display = 'none';
        document.querySelector('#dial_out_button').style.display = 'inline-block';
      });

      Twilio.Device.on('incoming', function (conn) {
        console.log('Incoming connection from ' + conn.parameters.From);
        conn.accept();
      });

      document.querySelector('#dial_out_button').addEventListener('click', function(e) {
        e.preventDefault();

        let phoneNumber = document.querySelector('#showing_phone').value;
        if (phoneNumber != '') {
          call(phoneNumber);
          document.querySelector('#dial_out_button').style.display = 'none';
          document.querySelector('#hangup_button').style.display = 'inline-block';
        }
      });

      document.querySelector('#hangup_button').addEventListener('click', function(e){
        e.preventDefault();
        hangup();

        document.querySelector('#hangup_button').style.display = 'none';
        document.querySelector('#dial_out_button').style.display = 'inline-block';
      });
    }
  }

  initShowConfirmation() {
    document.getElementById('request_document_link').addEventListener('click', () => {
      if (document.querySelector('#showing_phone').value =='') {
        alert('Please provide a phone number');
        return;
      }

      const html = `<div class="sm-modal-content">
        <div id="confirm_message">
          A text message requesting documents </br> will be sent to ${document.querySelector('#showing_phone').value}
        </div>
        <div class="buttons-list right-aligned">
          <a class="btn btn-primary js-send-message">Send message</a>
          <a class="btn btn-default js-modal-close-trigger">Cancel</a>
        </div></div>`;
      const path = this.getParam('requestDocumentPath');
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: html,
        modalId: 'confirm-message-modal',
        hideCloseButton: true,
        onComplete: () => {
          document.querySelector('.js-send-message').addEventListener('click', () => {
            fetch(path, {method: 'POST', headers: {
              'X-CSRF-Token': FetchUtils.CSRFToken()
            }, body: JSON.stringify({phone: document.querySelector('#showing_phone').value})}).then(FetchUtils.checkResponseStatus)
            .then(() => {
              document.querySelector('#document_requested_label').style.display = 'inline-block';
              document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {modalId: 'confirm-message-modal'}}));
            }).catch((err) => {
              FetchUtils.handleResponseError(err);
            });
          });
        }
      }}));
    });
  }

  init(loadShowtimes = false) {
    new TimePicker().init();
    prospectVerificationMethod.init();
    window.submitted = false;

    if (JSON.parse(this.getParam('showTwilio'))) {
      this.initTwilio();
    }

    if (JSON.parse(this.getParam('leadDistributionCalendar'))) {
      this.initLeadDistributionCalendar(loadShowtimes);
    }

    if (JSON.parse(this.getParam('showConfirmation'))) {
      this.initShowConfirmation();
    }

    document.querySelector('#dialog-confirm').style.display = 'none';

    this.bindEvents();
  }
}

function initShowingsForm() {
  if (document.querySelector('.js-showing-popup-wrapper')) {
    const sf = new ShowingsForm();
    sf.init(false);
    document.addEventListener('showings-popup:reloaded', () => {sf.init(true);});
    document.addEventListener('listing:changed', () => {
      new TimePicker().init();
    });

    document.dispatchEvent(new CustomEvent('showtime-data-updated'));
  }
}

// For general ShowMojo application
document.addEventListener('sm-modal:opened', (e) => {
  if (e.detail.modalContainer && e.detail.modalContainer.querySelector('.js-showing-popup-wrapper')) {
    initShowingsForm();
  }
});

// For old mobile /m application
document.addEventListener('DOMContentLoaded', () => {
  initShowingsForm();
});
