class ListingOptions {
  toggle_text(el){
    if(document.querySelector('select.' + el).selectedOptions[0].value == 'true'){
      document.querySelector('input.' + el )?.setAttribute('value','true');
      LayoutUtils.show(document.querySelector('.enabled_text.'+ el));
    }
    else {
      document.querySelector('input.' + el )?.removeAttribute('value');
      LayoutUtils.hide(document.querySelector('.enabled_text.' + el));
    }
  }

  check_accept_type() {
    if(document.querySelector('select#listing_accept_type').value == 'ACCEPT_TYPE_JUST_ME'){
      LayoutUtils.hide(document.querySelector('#showing-acceptance-contacts'));
    }
  }

  updateMap() {
    if (document.querySelector('input#listing_masking_street_1').value == '' ||
      document.querySelector('input#listing_masking_street_2').value == '') {
      return false;
    }
    let address = document.querySelector('input#listing_masking_street_1').value + ' @ ' +
      document.querySelector('input#listing_masking_street_2').value;
    let city = document.querySelector('input#listing_city').value;
    let state = document.querySelector('select#listing_state').value;
    AddressMapUtils.updateMapboxMapLink('div#listing_options', address, city, state);
    return true;
  }

  bindEvents() {
    document.querySelector('select#listing_masking_enable')?.addEventListener('change', (e) => {
      if (e.target.value == 'false') {
        let address = document.querySelector('input#listing_address').val();
        let city = document.querySelector('input#listing_city').val();
        let state = document.querySelector('select#listing_state').val();
        if (document.querySelector('input#listing_address').val() != '') {
          AddressMapUtils.updateMapboxMapLink('div#essentials', address, city, state);
        }
      } else {
        AddressMapUtils.checkMap('listing_options');
        this.updateMap();
      }
    });

    document.querySelectorAll('input#listing_masking_street_1, input#listing_masking_street_2').forEach((el) => {
      el.addEventListener('change', () => {
        const updated = this.updateMap();
        if (updated) {
          AddressMapUtils.checkMap('listing_options');
        }
      });
    });

    const toggle_text = this.toggle_text;

    document.querySelectorAll('select.listing_masking_enable,select.listing_secondary_listings_enable,select.listing_secondary_listings_enable,select.listing_custom_showing_details_enabled,select.listing_custom_confirmation_reply_enabled').forEach((el) => {
      el.addEventListener('change', function(e) {
        toggle_text(e.target.getAttribute('class'));
      });
    });

    document.querySelector('#listing_options form').addEventListener('cancelled', () =>  {
      toggle_text('listing_masking_enable');
      toggle_text('listing_custom_showing_details_enabled');
      toggle_text('listing_custom_confirmation_reply_enabled');
      document.querySelector('#listing_block_scheduled_showings')?.dispatchEvent(new Event('change'));
      this.check_accept_type();
    });
  }

  init() {
    this.bindEvents();

    document.querySelector('#edit-listing-options').addEventListener('click', function(){
      document.querySelector('#listing_options form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrap = document.querySelector('#listing_options');
  if (wrap) {
    new ListingOptions().init();

    document.addEventListener('html-updated', (e) => {
      if (e.detail?.container == '#listing_options') {
        new ListingOptions().init();
      }
    });
  }
});
