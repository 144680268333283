class SelfShowings {
  #lockboxCalendarBehaviourAccompanied;
  #lockboxCalendarBehaviorBroker;
  #lockboxAccompaniedMessage;
  #lockboxCalendarBehaviourLockbox;
  #lockboxMessage;
  #lockboxRequiresProspectChoice;
  #lockboxCalendarBehavior;

  setMessageField(select, newVal) {
    const msgField = select.closest('form').querySelector('.note_msg');
    if (msgField) {
      var message = '';
      if (newVal == this.#lockboxCalendarBehavior) {
        msgField.textContent = '';
        return;
      } else if (newVal == this.#lockboxCalendarBehaviourAccompanied || newVal == this.#lockboxCalendarBehaviorBroker) {
        message = this.#lockboxAccompaniedMessage;
      } else if (newVal == this.#lockboxCalendarBehaviourLockbox) {
        message = this.#lockboxMessage;
      }
      msgField.innerHTML = message;
    }
  }

  bindEvents() {
    const listingLockboxCalendarBehavior = document.querySelector('select.listing_lockbox_calendar_behavior');

    listingLockboxCalendarBehavior.addEventListener('change', e => {
      const select = e.currentTarget;
      const newVal = select.value;
      this.setMessageField(select, newVal);

      const selects = [...document.querySelectorAll('select.listing_lockbox_calendar_behavior')];
      const index = selects.indexOf(select);
      if (index >= 0) {
        selects.splice(index, 1);
      }
      selects.forEach(s => s.value = newVal);
      const brokerText = document.querySelector('.listing_lockbox_calendar_behavior_broker_text');
      const callVerification = document.querySelector('.js-phone-call-verification');
      if (newVal == this.#lockboxCalendarBehaviourAccompanied) {
        document.querySelector('.listing_lockbox_calendar_behavior_accompanied_text').style.display = 'block';
        document.querySelector('.listing_lockbox_calendar_behavior_lockbox_text').style.display = 'none';
        if (brokerText) {
          brokerText.style.display = 'block';
        }
        document.querySelector('.listing_lockbox_calendar_behavior_broker_switch').style.display = 'none';
        document.querySelector('.required_question_for_lockbox').style.display = 'block';
        if (callVerification) {
          callVerification.style.display = 'block';
        }
      } else if (newVal == this.#lockboxCalendarBehaviourLockbox) {
        document.querySelector('.listing_lockbox_calendar_behavior_accompanied_text').style.display = 'none';
        document.querySelector('.listing_lockbox_calendar_behavior_lockbox_text').style.display = 'block';
        if (brokerText) {
          brokerText.style.display = 'block';
        }
        document.querySelector('.listing_lockbox_calendar_behavior_broker_switch').style.display = 'none';
        document.querySelector('.required_question_for_lockbox').style.display = 'block';
        if (callVerification) {
          callVerification.style.display = 'block';
        }
      } else {
        document.querySelector('.listing_lockbox_calendar_behavior_accompanied_text').style.display = 'none';
        document.querySelector('.listing_lockbox_calendar_behavior_lockbox_text').style.display = 'none';
        if (brokerText) {
          brokerText.style.display = 'none';
        }
        document.querySelector('.listing_lockbox_calendar_behavior_broker_switch').style.display = 'block';
        document.querySelector('.required_question_for_lockbox').style.display = 'none';
        if (callVerification) {
          callVerification.style.display = 'none';
        }
      }
    });

    document.querySelector('.listing_required_question_for_lockbox').addEventListener('change', e => {
      const select = e.currentTarget;
      const new_val = select.value;
      const textField = document.querySelector('.listing_lockbox_additional_screening_question_prospect_choice_text');

      const selects = [...document.querySelectorAll('select.listing_required_question_for_lockbox')];
      const index = selects.indexOf(select);
      if (index >= 0) {
        selects.splice(index, 1);
      }
      selects.forEach(s => s.value = new_val);
      if (new_val == this.#lockboxRequiresProspectChoice) {
        textField.style.display = 'block';
      } else {
        textField.style.display = 'none';
      }
    });
  }

  init(form) {
    this.#lockboxCalendarBehaviourAccompanied = form.dataset.lockboxCalendarBehaviourAccompanied;
    this.#lockboxCalendarBehaviorBroker = form.dataset.lockboxCalendarBehaviorBroker;
    this.#lockboxAccompaniedMessage = form.dataset.lockboxAccompaniedMessage;
    this.#lockboxCalendarBehaviourLockbox = form.dataset.lockboxCalendarBehaviourLockbox;
    this.#lockboxMessage = form.dataset.lockboxMessage;
    this.#lockboxRequiresProspectChoice = form.dataset.lockboxRequiresProspectChoice;
    this.#lockboxCalendarBehavior = form.dataset.lockboxCalendarBehavior;

    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('.js-self-showings-form');
  if (form) {
    const selfShowings = new SelfShowings();
    selfShowings.init(form);
    document.addEventListener('html-updated', (e) => {
      if (e.detail && e.detail.container == '#lockbox_defaults_settings') {
        selfShowings.init(document.querySelector('.js-self-showings-form'));
      }
    });
  }
});
