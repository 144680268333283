class OptionsForm {
  bindEvents(form) {
    document.querySelector('#edit-listing-options').addEventListener('click', () => {
      form.dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    document.querySelector('#listing_application_link_policy')?.addEventListener('change', function(e) {
      if (e.target.value == 'APPLICATION_LINK_POLICY_CUSTOM_LINK') {
        LayoutUtils.show(document.querySelector('#custom_application_url'));
      } else {
        LayoutUtils.hide(document.querySelector('#custom_application_url'));
      }
    });

    document.querySelector('#listing_block_scheduled_showings')?.addEventListener('change', function(e) {
      const blockShowingsValue = e.target.value;
      if (blockShowingsValue == 'BEFORE') {
        LayoutUtils.show(document.querySelector('span#block_scheduled_showings_before'));
        document.querySelectorAll('#listing_block_until, #listing_block_until_time').forEach((el) => {
          el.removeAttribute('disabled');
        });
        LayoutUtils.hide(document.querySelector('span#block_scheduled_showings_after'));
        document.querySelectorAll('#listing_block_from, #listing_block_from_time').forEach((el) => {
          el.setAttribute('disabled', 'disabled');
        });
      } else if (blockShowingsValue == 'AFTER') {
        LayoutUtils.show(document.querySelector('span#block_scheduled_showings_after'));
        document.querySelectorAll('#listing_block_from, #listing_block_from_time').forEach((el) => {
          el.removeAttribute('disabled');
        });
        LayoutUtils.hide(document.querySelector('span#block_scheduled_showings_before'));
        document.querySelectorAll('#listing_block_until, #listing_block_until_time').forEach((el) => {
          el.setAttribute('disabled', 'disabled');
        });
      } else if (blockShowingsValue == 'BETWEEN') {
        LayoutUtils.show(document.querySelectorAll('span#block_scheduled_showings_after, span#block_scheduled_showings_before'));
        document.querySelectorAll('#listing_block_from, #listing_block_from_time, #listing_block_until, #listing_block_until_time').forEach((el) => {
          el.removeAttribute('disabled');
        });
      } else {
        LayoutUtils.hide(document.querySelectorAll('span#block_scheduled_showings_after, span#block_scheduled_showings_before'));
        document.querySelectorAll('#listing_block_from, #listing_block_from_time, #listing_block_until, #listing_block_until_time').forEach((el) => {
          el.setAttribute('disabled', 'disabled');
        });
      }
    });
  }

  init(form) {
    this.bindEvents(form);

    flatpickr('#listing_block_until, #listing_block_from', {dateFormat: 'M j, Y', allowInput: true});

    document.querySelector('#listing_application_link_policy')?.dispatchEvent(new Event('change'));

    document.querySelector('#listing_block_scheduled_showings')?.dispatchEvent(new Event('change'));
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#listing_options form');
  if (form) {
    new OptionsForm().init(form, false);
  }

  document.addEventListener('options-form:updated', (e) => {
    const form = document.querySelector('#listing_options form');
    new OptionsForm().init(form);
  });
});
