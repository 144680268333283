import MojolockBase from './mojolock_base';

class MojolockPromo extends MojolockBase {
  #price;

  constructor() {
    super();
    this.#price = DeviceUtils.getOrderPageData().mojolockPromoPrice;
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {
    const el = document.querySelector('strong#fixed_mojolock_price');
    if (el) {
      el.innerText = '$' + this.#price;
    }
  }
}

export default MojolockPromo;