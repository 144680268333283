class ShowingNotification {
  toggleShowingNotificationDetails(el) {
    if(document.querySelector('select.' + el).selectedOptions[0].value == 'true') {
      document.querySelector('input.' + el ).setAttribute('value','true');
      LayoutUtils.show(document.querySelector('.showing-notification-details'));
    } else {
      document.querySelector('input.' + el ).removeAttribute('value');
      LayoutUtils.hide(document.querySelector('.showing-notification-details'));
    }
  }

  bindEvents(form) {
    document.querySelector('select.listing_notify_tenants').addEventListener('change', () => {
      this.toggleShowingNotificationDetails('listing_notify_tenants');
    });

    document.querySelector('#edit-showing-notification').addEventListener('click', () => {
      form.dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    form.addEventListener('cancelled', () => {
      this.toggleShowingNotificationDetails('listing_notify_tenants');
    });
  }

  init(form, withErrors) {
    this.bindEvents(form);
    if (withErrors) {
      form.dispatchEvent(new CustomEvent('partial-form:enable'));
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#showing_notification form');
  if (form) {
    new ShowingNotification().init(form, false);
  }

  document.addEventListener('showing-notification:updated', (e) => {
    const form = document.querySelector('#showing_notification form');
    let withErrors = false;
    if (e.detail && JSON.parse(e.detail.errors)) {
      withErrors = true;
    }
    new ShowingNotification().init(form, withErrors);
    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#showing_notification'}}));
  });
});

