class RentalApplicationSignature {
  initTemplateEditor(field) {
    if (!document.querySelector('#' + field.id)) { return; }
    this.addRichTemplateEditor(field);
  }

  addRichTemplateEditor(field) {
    var settings = {
      toolbar: ['bold', 'italic', 'insert'],
      unusedMenuItems: ['Insert Horizontal Line'],
      editorHeight: 130,
      enable: true
    };
    return new window.SMTemplateRichEditor().init(field.id, settings);
  }

  init(field) {
    this.initTemplateEditor(field);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const field = document.querySelector('#js-ra-signature-field');
  if (field) {
    new RentalApplicationSignature().init(field);
  }
});
