/**
 * Utils for typical layout changes.
 * Hide/show elements, change content visibility depending on the select or radio button value etc.
 */
class LayoutUtils {
  static HIDDEN_CLASS = 'hidden';

  // Add 'hidden' class for list of nodes or for single node
  static hide(elements) {
    if (elements) {
      if (elements instanceof NodeList) {
        elements.forEach(el => {
          el.classList.add(LayoutUtils.HIDDEN_CLASS);
        });
      } else {
        elements.classList.add(LayoutUtils.HIDDEN_CLASS);
      }
    }
  }

  // Remove 'hidden' class for list of nodes or for single node
  static show(elements) {
    if (elements) {
      if (elements instanceof NodeList) {
        elements.forEach(el => {
          el.classList.remove(LayoutUtils.HIDDEN_CLASS);
        });
      } else {
        elements.classList.remove(LayoutUtils.HIDDEN_CLASS);
      }
    }
  }

  // Toggle 'hidden' class for list of nodes or for single node
  // @param elements - list of nodes or for single node
  // @param show [true, false, undefined] - if value is undefined, then hidden state is changed to opposite
  static toggle(elements, show) {
    if (show) {
      LayoutUtils.show(elements);
    } else if (show == undefined) {
      if (elements) {
        if (elements instanceof NodeList) {
          elements.forEach(el => {
            LayoutUtils.toggle(el, el.classList.contains('hidden'));
          });
        } else {
          LayoutUtils.toggle(elements, elements.classList.contains('hidden'));
        }
      }
    } else {
      LayoutUtils.hide(elements);
    }
  }

  // Change content visibility depending on the select or radio button value
  // Select/radio button options should have 'js-option' class.
  // Each content section should have data-value attribute corresponding selected option value
  // @param contentSelector - each content section that will be shown/hidden should have this selector
  // @param wrapper - node container for whole elements
  static showContentBySelected(contentSelector, wrapper) {
    wrapper.querySelectorAll('.js-option').forEach(el => {
      el.addEventListener('click', () => {
        LayoutUtils.hide(wrapper.querySelectorAll(contentSelector));
        LayoutUtils.show(wrapper.querySelector(`[data-value=${el.value}]`));
      });
    });
  }

  static startLoader(form) {
    const element = document.createElement('div');
    element.className = 'ajax-loader-medium internal-loader';
    element.style.width = form.offsetWidth;
    element.style.height = form.offsetHeight;
    form.prepend(element);
  }

  static stopLoader(form) {
    const element = form.getElementsByClassName('ajax-loader-medium internal-loader')[0];
    element.remove();
  }

  static highlight(element, timeout = 750) {
    if (element) {
      element.classList.add('sm-highlighted');
      setTimeout(function() {
        element.classList.remove('sm-highlighted');
      }, timeout);
    }
  }

  static disableButton(btns) {
    if (btns instanceof NodeList) {
      btns.forEach((btn) => {
        btn.classList.add('disabled');
        btn.setAttribute('disabled', true);
      });
    } else if (btns) {
      btns.classList.add('disabled');
      btns.setAttribute('disabled', true);
    }
  }

  static enableButton(btns) {
    if (btns instanceof NodeList) {
      btns.forEach((btn) => {
        btn.classList.remove('disabled');
        btn.removeAttribute('disabled', true);
      });
    } else if (btns) {
      btns.classList.remove('disabled');
      btns.removeAttribute('disabled', true);
    }
  }

  static replaceWith(oldNode, newHtml) {
    if (oldNode) {
      const parent = oldNode.parentNode;
      const newNode = document.createElement('div');
      newNode.innerHTML = newHtml;

      parent.replaceChild(newNode.children[0], oldNode);
    }
  }
}

export default LayoutUtils;
