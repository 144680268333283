class Pets {
  getHtml({schedulingRestrictions, petsQuestionEnd, petsQuestionStart, petsQuestionMid}, questionDiv) {
    let select = '';
    if (JSON.parse(schedulingRestrictions)) {
      select =`<select class="partialform-edit will_wont_select"
      name="question[][correct_answer]"
      style="display: inline-block;"> <option value="false">Do not allow</option>
      <option value="true" selected="selected">Allow</option></select>
       ${petsQuestionEnd}</small></span>`;
    }
    const text = questionDiv.querySelector('[data-binding="text"]');
    text.value = petsQuestionStart;
    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);
    text.insertAdjacentHTML('afterend', '<br><small>' + petsQuestionMid + select);

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return false;
  }
}

window.Pets = Pets;
