class Income {
  getHtml({incomeOptions, inMesage, incomeAllowingQuestionMessage}, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');
    const span = document.createElement('span');
    span.classList.add('system_question');
    text.parentElement.insertBefore(span, text);
    span.appendChild(text);

    const s = document.createElement('select');
    s.id = 'account_showing_income_limitation';
    s.classList.add('partialform-edit', 'will_wont_select', 'income_drop_down');
    s.name = 'question[][correct_answer]';

    incomeOptions = incomeOptions.split(',');

    for(let val in incomeOptions) {
      const opt = document.createElement('option');
      opt.value = incomeOptions[val];
      opt.text = incomeOptions[val];
      s.add(opt);
    }

    var after_message = `<br> <small id='income_content'>${incomeAllowingQuestionMessage}</small>`;

    text.value = inMesage;
    text.insertAdjacentHTML('afterend', after_message);
    questionDiv.querySelector('#income_content').after(s);

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return false;
  }
}

window.Income = Income;
