class ShowingAcceptanceIndividualForm {
  init(form) {
    if (!form.dataset.inited) {
      form.dataset.inited = 'true';
    }
  }
}

class ShowingAcceptanceSection {
  toggleShowingAcceptanceDetails (el) {
    if(document.querySelector('select.' + el).selectedOptions[0].value == 'true') {
      document.querySelector('input.' + el ).setAttribute('value','true');
      LayoutUtils.show(document.querySelector('#showtime_acceptance'));
    } else {
      document.querySelector('input.' + el ).setAttribute('value','false');
      LayoutUtils.hide(document.querySelector('#showtime_acceptance'));
    }
  }

  bindEvents() {
    document.querySelector('a#edit-showing-acceptance').addEventListener('click', function() {
      document.querySelector('#showing_acceptance form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    document.querySelector('#showing_acceptance form').addEventListener('cancelled', () => {
      this.toggleShowingAcceptanceDetails('listing_accept_enable');
    });

    document.querySelector('select.listing_accept_enable').addEventListener('change', () => {
      this.toggleShowingAcceptanceDetails('listing_accept_enable');
    });

    document.querySelector('select#listing_accept_type').addEventListener('change', function(e) {
      if(e.target.value == 'ACCEPT_TYPE_JUST_ME'){
        LayoutUtils.hide(document.querySelector('#showing-acceptance-contacts'));
      }
      else {
        LayoutUtils.show(document.querySelector('#showing-acceptance-contacts'));
      }
    });
  }

  init() {
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const forms = document.querySelectorAll('.js-showing-acceptance-form');
  forms.forEach(form => {
    new ShowingAcceptanceIndividualForm().init(form);
  });

  document.addEventListener('showing-acceptance:updated', () => {
    const forms = document.querySelectorAll('.js-showing-acceptance-form');
    forms.forEach(form => {
      new ShowingAcceptanceIndividualForm().init(form);
    });

    const section = document.querySelector('#showing_acceptance');
    if (section) {
      new ShowingAcceptanceSection().init();
    }

    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#showing_acceptance'}}));
  });

  const section = document.querySelector('#showing_acceptance');
  if (section) {
    new ShowingAcceptanceSection().init();
  }
});
