class Roommates {
  getHtml(data, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');

    text.value = 'Will you have any roommates?';
    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);
    text.insertAdjacentHTML('afterend', '<br><small>(Prospects can provide up to four names or email addresses.)</small>');
    const answRequired = questionDiv.querySelector('[data-binding="answer_required"]');
    answRequired.setAttribute('disabled', 'disabled');

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return true;
  }
}

window.Roommates = Roommates;
