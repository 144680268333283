const coordinateMatch = {
  dmm: /(-?\d+)\s+(.+),\s+(-?\d+)\s+(.+)/,
  dd: /(-?\d+\.?\d*),\s*(-?\d+\.?\d*)/,
  dms: /(-?\d+)°\s*(\d+)'\s*(.+)"N\s+(-?\d+)°\s*(\d+)'\s*(.+)"E/,
};

const changeToDMM = (value) => {
  value = parseFloat(value);

  const minute = ((value - Math.floor(value)) * 60).toFixed(4);
  return `${Math.floor(value)} ${minute}`;
};

const changeToDMS = (value) => {
  value = parseFloat(value);

  const minute = (value - Math.floor(value)) * 60;
  const second = ((minute - Math.floor(minute)) * 60).toFixed(1);
  return `${Math.floor(value)}°${Math.floor(minute)}'${second}"`;
};

const calcFromDMS = (d, m, s = 0) => {
  return d + m / 60 + s / 3600;
};

class OverrideLocation {
  #isHereMap;
  #lat;
  #long;
  #zoom;
  #hereApiKey;
  #mapboxApiKey;
  #coordinateType;

  storeCoordinates(lat, long, format = true) {
    document.querySelectorAll('input[name="listing[lat]"]').forEach(el => el.value = lat);
    document.querySelectorAll('input[name="listing[long]"]').forEach(el => el.value = long);
    document.querySelector('input[name="override_location_flag"]').value = 'true';
    if(!format) return;

    let markerPosition;
    switch(this.#coordinateType) {
    case 'dmm':
      markerPosition = `${changeToDMM(lat)}, ${changeToDMM(long)}`; break;
    case 'dms':
      markerPosition = `${changeToDMS(lat)}N ${changeToDMS(long)}E`; break;
    default:
      markerPosition = `${lat.toFixed(5)}, ${long.toFixed(5)}`;
    }
    document.querySelector('#coordinates').value = markerPosition;
  }

  addDraggableMarker(map, marker, behavior) {
    // Ensure that the marker can receive drag events
    marker.draggable = true;
    map.addObject(marker);
    this.storeCoordinates(this.#lat, this.#long);

    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    map.addEventListener('dragstart', (ev) => {
      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        var targetPosition = map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    map.addEventListener('dragend', (ev) => {
      var target = ev.target;
      if (target instanceof H.map.Marker) {
        behavior.enable();

        var position = target.getGeometry();
        this.storeCoordinates(position['lat'], position['lng']);
      }
    }, false);

    // Listen to the drag event and move the position of the marker
    // as necessary
    map.addEventListener('drag', (ev) => {
      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
      }
    }, false);
  }

  initHereMap() {
    // Initialize HERE Map API
    var platform = new H.service.Platform({apikey: this.#hereApiKey, useHTTPS: true });

    // Obtain the default map types from the platform object:

    var mapLayer = platform.getMapTileService({type: 'base'}).createTileLayer('maptile', 'reduced.day', 256, 'png8');

    // Instantiate (and display) a map object:
    var map = new H.Map(document.querySelector('#map'), mapLayer, { zoom: this.#zoom, center: { lat: this.#lat, lng: this.#long }, imprint: null });

    // Enable the event system on the map instance and
    // instantiate the default behavior, providing the mapEvents object
    var mapBehavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => map.getViewPort().resize());

    // Create the default UI:
    var ui = H.ui.UI.createDefault(map, mapLayer);
    // Remove MapSettings Control
    ui.removeControl('mapsettings');
    // Remove ScaleBar Control
    ui.removeControl('scalebar');
    // Move Zoom Control to the left-top corner
    ui.getControl('zoom').setAlignment('left-top');

    // Adds a  draggable marker to the map
    var marker = new H.map.Marker({ lat: this.#lat, lng: this.#long }, {
      // mark the object as volatile for the smooth dragging
      volatility: true
    });
    this.addDraggableMarker(map, marker, mapBehavior);

    document.querySelector('#coordinates').addEventListener('input', (e) => this.handleChange(map, marker, e.target.value));
  }

  initMapBox() {
    L.mapbox.accessToken = this.#mapboxApiKey;
    let map = L.mapbox.map('map', 'mapbox.mapbox-streets-v8').setView([this.#lat, this.#long], this.#zoom);
    var marker = L.marker([this.#lat, this.#long], { draggable: true });
    marker.addTo(map);

    marker.on('dragend', (e) => {
      var target = e.target;
      var position = target.getLatLng();
      this.storeCoordinates(position['lat'], position['lng']);
    }, false);
  }

  setLatAndLong(match) {
    switch(this.#coordinateType) {
    case 'dd': {
      this.#lat = parseFloat(match[1]);
      this.#long = parseFloat(match[2]);
      break;
    }
    case 'dms': {
      const second1 = parseFloat(match[3]);
      const second2 = parseFloat(match[6]);
      if(Number.isNaN(second1) || Number.isNaN(second2)) break;
      this.#lat = calcFromDMS(parseInt(match[1]), parseInt(match[2]), second1);
      this.#long = calcFromDMS(parseInt(match[4]), parseInt(match[5]), second2);
      break;
    }
    case 'dmm': {
      const minute1 = parseFloat(match[2]);
      const minute2 = parseFloat(match[4]);
      if(Number.isNaN(minute1) || Number.isNaN(minute2)) break;
      this.#lat = calcFromDMS(parseInt(match[1]), minute1);
      this.#long = calcFromDMS(parseInt(match[3]), minute2);
      break;
    }}
  }

  handleChange(map, marker, value) {
    let match;
    for(let key in coordinateMatch) {
      if(match) break;
      match = value.match(coordinateMatch[key]);
      if(match) {
        this.#coordinateType = key;
        this.setLatAndLong(match);
      }
    }

    if(match) {
      map.setCenter({
        lat: this.#lat,
        lng: this.#long
      });
      marker.setGeometry({lat: this.#lat, lng: this.#long});
      this.storeCoordinates(this.#lat, this.#long, false);
    }
  }

  init(wrap) {
    this.#isHereMap = wrap.dataset.isHereMap;
    this.#long = parseFloat(wrap.dataset.long);
    this.#lat = parseFloat(wrap.dataset.lat);
    this.#zoom = parseInt(wrap.dataset.zoom);
    this.#hereApiKey = wrap.dataset.hereApiKey;
    this.#mapboxApiKey = wrap.dataset.mapboxApiKey;

    if (parseInt(this.#isHereMap)) {
      this.initHereMap();
    } else {
      // Show MapBox map if we have MapBox or an unexpected map provider
      this.initMapBox();
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initLocationBtn() {
    const setLocationBtn = document.querySelector('.manually_set_location_button');
    const isNew = parseInt(setLocationBtn.dataset.new);
    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
      url: setLocationBtn.dataset.href,
      onComplete: () => {
        var resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);

        const wrap = document.querySelector('.js-override-location-wrap');
        if (wrap) {
          new OverrideLocation().init(wrap);
        }
        const setLocationModal = document.querySelector('.js-modal-submit');
        if(setLocationModal) {
          setLocationModal.addEventListener('click', function() {
            document.dispatchEvent(new CustomEvent('sm-modal:close'));
            LayoutUtils.show(document.querySelector('.js-modal-loader'));
            if (isNew) {
              let notify_confirmation_flag = document.querySelector('#notify_confirmation_flag').value;
              ListingEssentialsForm.submitForm(notify_confirmation_flag === 'true');
            }
          });
        }
      }
    }}));
  }


  document.addEventListener('listing:new', () => {
    const setLocationBtn = document.querySelector('.manually_set_location_button');
    if (setLocationBtn) {
      setLocationBtn.addEventListener('click', function(e) {
        e.preventDefault();
        initLocationBtn();
      });
    }
  });

  const setLocationBtn = document.querySelector('.manually_set_location_button');
  if (setLocationBtn) {
    setLocationBtn.addEventListener('click', function(e) {
      e.preventDefault();
      initLocationBtn();
    });
  }
});
