class Custom {
  getHtml({answerLinesSelect}, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');
    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);
    text.insertAdjacentHTML('afterend', `<br><small>Prospect will be offered a text field ${JSON.parse(answerLinesSelect)} to answer the question.</small>`);

    return questionDiv;
  }

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  bindEvents() {}

  isSingleQuestion() {
    return false;
  }
}

window.Custom = Custom;
