import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import FetchUtils from 'src/utils/fetch_utils';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

class ShowingsCalendar {

  #calendar;
  #resizeTimer;
  #moreLinkClicked;
  #moreEventsCount;
  #prospectsActionsCache = {};
  #CACHE_LIMIT = 1000;

  getViewportWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  }

  isMobileViewport() {
    return this.getViewportWidth() < 520;
  }

  closePopovers() {
    document.querySelectorAll('.popover').forEach(p => {
      p.remove();
    });
  }

  setMaxEventRows() {
    if (this.isMobileViewport()) {
      this.#calendar.setOption('dayMaxEventRows', 0);
    } else {
      this.#calendar.setOption('dayMaxEventRows', true);
    }
  }

  setCalendarHeight() {
    const heigthBreakpoint = 700;
    if (window.innerHeight < heigthBreakpoint) {
      this.#calendar.setOption('height', 'auto');
    } else {
      this.#calendar.setOption('height', '100%');
    }
  }

  bindEvents() {
    window.onresize = () => {
      this.setMaxEventRows();
      this.setCalendarHeight();
      clearTimeout(this.#resizeTimer);
      this.#resizeTimer = setTimeout(() => {
        this.#calendar.updateSize();
      }, 500);
    };

    window.onkeydown = (e) => {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 27) {
        this.closePopovers();
      }
    };

    document.addEventListener('html-updated', e => {
      if (e.detail && e.detail.container == '#showings_box') {
        this.#calendar.refetchEvents();
        this.clearActionsCache();
      }
    });

    document.querySelector('body').addEventListener('click', e => {
      if (!e.target.classList.contains('js-toggle-link-bars')) {
        this.closePopovers();
      }
    });

    document.addEventListener('sidebar:state-changed', () => {
      this.#calendar.updateSize();
    });
  }

  createTippy(el, label, prospect) {
    tippy(el, {
      allowHTML: true,
      content: `<ul class="showings-calendar-tooltip"><li class="calendar-tooltip-listing">${label}</li>${prospect}</ul>`,
      theme: 'dark',
      onTrigger(instance, event) {
        if (event.fromElement && event.fromElement.classList.contains('fc-more-link')) {
          instance.disable();
        }
      },
      onUntrigger: (instance) => {
        instance.enable();
      }
    });
  }

  getProspectHtml(prospect) {
    return `<li><b>${prospect.name}</b></li><li>${prospect.email}</li><li>${prospect.phone}</li>`;
  }

  createTooltip(info) {
    const data = info.event.extendedProps;
    if (data.prospects.length <= 1) {
      this.createTippy(info.el, data.label, this.getProspectHtml(data.prospects[0]));
    }
  }

  disableButtons() {
    const btns = document.querySelector('.fc-button-group');
    if (btns) {
      document.querySelector('.fc-toolbar-title').insertAdjacentHTML('beforeend', '<div class="js-events-loader events-loader"><div class="ajax-loader-small"></div></div>');
      btns.classList.add('disabled');
    }
  }

  enableButtons() {
    const btns = document.querySelector('.fc-button-group');
    if (btns) {
      document.querySelector('.js-events-loader').remove();
      document.querySelector('.fc-button-group').classList.remove('disabled');
    }
  }

  hasSeveralProspects(wrapper) {
    return wrapper.querySelectorAll('.js-calendar-event-prospects li').length > 1;
  }

  adjustCellHeight(wrapper) {
    const prospectsClasses = wrapper.querySelector('.js-calendar-event-prospects').classList;
    const tr = wrapper.closest('tr');
    if (!tr) return;
    const isProspectsClosed = prospectsClasses.contains('hidden-prospects');
    document.querySelectorAll('.js-calendar-event-prospects').forEach(p => {
      const row = p.closest('tr');
      row.style.height = `${row.dataset.initialHeight}px`;
      p.classList.add('hidden-prospects');
    });
    if (isProspectsClosed) {
      tr.dataset.initialHeight = tr.dataset.initialHeight ?  tr.dataset.initialHeight : tr.offsetHeight;
      prospectsClasses.remove('hidden-prospects');
      const cellHeight = wrapper.closest('.fc-daygrid-day-events').offsetHeight + wrapper.closest('td').querySelector('.fc-daygrid-day-top').offsetHeight + 5;
      tr.style.height = `${Math.max(cellHeight, tr.offsetHeight)}px`;
    } else {
      prospectsClasses.add('hidden-prospects');
      tr.style.height = `${tr.dataset.initialHeight}px`;
    }
  }

  hideAllPopovers() {
    SMDropdown.closeAll();
  }

  clearActionsCache() {
    for (const prop of Object.getOwnPropertyNames(this.#prospectsActionsCache)) {
      delete this.#prospectsActionsCache[prop];
    }
  }

  storeActionsToCache(prospectId, actions) {
    if (Object.keys(this.#prospectsActionsCache).length === this.#CACHE_LIMIT) {
      this.clearActionsCache();
    }
    this.#prospectsActionsCache[prospectId] = actions;
  }

  fetchActionsForProspect(prospectId) {
    const url = document.querySelector('.js-showings-calendar-view').dataset.actionsPath;
    return fetch(url.replace(':id', prospectId)).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(json => {
        this.storeActionsToCache(prospectId, json.actions);
        return json.actions;
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });
  }

  async showActionsPopover(event, wrapper, prospectId) {
    const prospect = event.extendedProps.prospects.find(p => {
      return p.id === prospectId;
    });

    const link = event.extendedProps.prospects.length > 1 ?
      wrapper.querySelector(`li[data-id="${prospectId}"]`) :
      wrapper.querySelector('.js-calendar-event-item');

    this.hideAllPopovers();

    let actions = '';

    if (this.#prospectsActionsCache.hasOwnProperty(prospectId)) {
      actions = this.#prospectsActionsCache[prospectId];
    } else {
      wrapper.closest('.fc-daygrid-event-harness').insertAdjacentHTML('afterbegin', '<div class="js-loader prospects-loader"><div class="ajax-loader-small"></div></div>');
      actions = await this.fetchActionsForProspect(prospectId);
      document.querySelector('.js-loader').remove();
    }

    SMDropdown.init(link, {
      trigger: 'manual',
      placement: 'auto',
      onHidden: () => {
        link._tippy.destroy();
      },
      content: `<div class="calendar-actions-popover js-calendar-actions-popover"><ul class="js-popover-content"><li class="no-hover prospect-actions-title">${prospect.name}</li>${actions}</ul></div>`
    }).show();

    document.querySelectorAll('.js-calendar-actions-popover a').forEach(l => {
      l.addEventListener('click', (e) => {
        if (!e.target.classList.contains('js-toggle-link-bars')) {
          this.closePopovers();
        }
      });
    });

    document.dispatchEvent(new CustomEvent('dashboard:init-popover-links'));
  }

  getDesktopEventHtml(event, timeText) {
    const prospects = event.extendedProps.prospects;

    let html = `<div class="calendar-event-item js-calendar-event-item js-popover-link">
      <span class="calendar-initials">${event.extendedProps.initials}</span>
      <span class="calendar-listing"><span class="calendar-listing-title">${event.extendedProps.label}</span></span>
      <span class="calendar-time">${timeText}</span>
      </div><ul class="calendar-event-prospects js-calendar-event-prospects hidden-prospects">`;

    prospects.forEach(p => {
      html += `<li data-id="${p.id}" class="js-popover-link"><span>${p.name}</span><div class="showing-actions js-showing-actions hidden"></div></li>`;
    });
    html += '</ul>';

    return html;
  }

  getDesktopEventDomNode(arg) {
    let node = document.createElement('span');
    node.innerHTML = this.getDesktopEventHtml(arg.event, arg.timeText);
    return node;
  }

  setMorePopoverPosition(info) {
    const popover = info.el.closest('.fc-more-popover');
    if (popover && popover.querySelectorAll('.calendar-event-item').length === this.#moreEventsCount) {
      const wrapperRect = document.querySelector('.fc-view-harness').getBoundingClientRect();
      const bottom = wrapperRect.bottom - this.#moreLinkClicked.getBoundingClientRect().bottom;
      popover.style.bottom = `${bottom}px`;
      const shift = wrapperRect.top - popover.getBoundingClientRect().top;
      if (shift > 0) {
        popover.style.bottom = `${bottom - shift}px`;
      }
    }
  }

  createCalendar(initialShowings) {
    const today = new Date();
    let timer = null;
    let initFlag = true;

    this.#calendar = new Calendar(document.querySelector('.js-showings-calendar'), {
      plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin ],
      timeZone: false,
      headerToolbar: {
        start: '',
        center: 'title',
        end: 'prev,next'
      },
      dayMaxEventRows: true,
      eventDisplay: 'block',
      eventBackgroundColor: 'rgba(27, 132, 158, 0.1)',
      eventTextColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
      eventBorderColor: 'transparent',
      initialDate: today.toISOString(),
      displayEventTime: true,
      fixedWeekCount: false,
      datesSet: () => {
        this.clearActionsCache();
      },
      eventClassNames: function(arg) {
        return `uid-${arg.event.extendedProps._id}`;
      },
      eventClick: ({event, jsEvent}) => {
        const clickedEl = document.elementFromPoint(jsEvent.x, jsEvent.y);
        const isClickOnProspects = clickedEl.closest('.js-calendar-event-prospects');
        const wrapper = clickedEl.closest(`.uid-${event.extendedProps._id}`);
        const hasSeveralProspects = this.hasSeveralProspects(wrapper);
        this.closePopovers();
        if (!isClickOnProspects && hasSeveralProspects) {
          this.adjustCellHeight(wrapper);
          this.#calendar.updateSize();
        } else {
          jsEvent.stopPropagation();
          //We can click on specific showing or group consists only of single showing
          const clickOnProspect = clickedEl.dataset.id || (clickedEl.closest('li') && clickedEl.closest('li').dataset.id);
          const id  = clickOnProspect || wrapper.querySelector('.js-calendar-event-prospects li').dataset.id;
          this.showActionsPopover(event, wrapper, id);
        }
      },
      moreLinkClick: ({allSegs, jsEvent}) => {
        this.#moreLinkClicked = jsEvent.currentTarget;
        this.#moreEventsCount = allSegs.length;
      },
      moreLinkContent: (arg) => {
        let html = '';
        if (this.isMobileViewport()) {
          html = '&#x2022;';
        } else {
          html = `${arg.num} more`;
        }
        return {html};
      },
      eventDidMount: (info) => {
        this.setMorePopoverPosition(info);
        this.createTooltip(info);
      },
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
      },
      eventContent: (arg) => {
        let node = this.getDesktopEventDomNode(arg);

        return { domNodes: [ node ] };
      },
      events: (info, successCallback, failureCallback) => {
        if (timer) {
          clearTimeout(timer);
        }
        if(initFlag && initialShowings){
          initFlag = false;
          this.enableButtons();
          successCallback(
            initialShowings.map(function(eventEl) {
              return {
                _id: eventEl.listing_uid,
                start: eventEl.datetime,
                end: eventEl.endtime,
                label: eventEl.label,
                prospects: eventEl.prospects,
                initials: eventEl.initials
              };
            })
          );
        } else {
          timer = setTimeout(() => {
            this.disableButtons();
            const url = '/showings/calendar_showings';
            fetch(`${url}?start=${info.start.valueOf()}&end=${info.end.valueOf()}`, {
              headers: {
                'Accept': 'application/json'
              }})
              .then(FetchUtils.checkResponseStatus)
              .then((resp) => {
                return resp.json();
              }).then(json => {
                this.enableButtons();
                successCallback(
                  json.map(function(eventEl) {
                    return {
                      _id: eventEl.listing_uid,
                      start: eventEl.datetime,
                      end: eventEl.endtime,
                      label: eventEl.label,
                      prospects: eventEl.prospects,
                      initials: eventEl.initials
                    };
                  })
                );
              }).catch((err) => {
                clearTimeout(timer);
                failureCallback(err);
              });
          }, 500);
        }
      }
    });

    this.setMaxEventRows();
    this.setCalendarHeight();
    this.#calendar.render();
  }

  init(initialShowings) {
    this.createCalendar(initialShowings);
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const calendarFilter = document.querySelector('.js-showings-calendar-view');
  if (calendarFilter) {
    const initialShowingsJson = document.querySelector('.js-showings-calendar-view').dataset.initialShowings;
    const initialShowings = JSON.parse(initialShowingsJson);
    new ShowingsCalendar().init(initialShowings);
  }
});
