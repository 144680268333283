class CustomDate {
  getHtml(data, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');
    text.value = '';
    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);
    text.insertAdjacentHTML('afterend', '<br><small>(Prospects can answer by selecting a specific date)</small>');

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return false;
  }
}

window.CustomDate = CustomDate;
