class Filters {
  handleIconClick(icon, previousIcon) {
    icon.style.opacity = 1;
    previousIcon.style.opacity = 0.2;
  }

  init(dashboard) {
    document.querySelector('.people_input')?.addEventListener('click', function(event){
      event.stopImmediatePropagation();
      event.target.select();
      return false;
    });

    document.querySelector('a.icon.people')?.addEventListener('click', (e) => {
      this.handleIconClick(e.currentTarget, document.querySelector('a.icon.location'));
      LayoutUtils.show(document.querySelector('#people_search'));
      LayoutUtils.hide(document.querySelector('#filter'));

      return false;
    });

    document.querySelector('a.icon.location')?.addEventListener('click', (e) => {
      this.handleIconClick(e.currentTarget, document.querySelector('a.icon.people'));
      LayoutUtils.hide(document.querySelector('#people_search'));
      LayoutUtils.show(document.querySelector('#filter'));

      return false;
    });

    if (dashboard.dataset.people && dashboard.dataset.people != '') {
      document.querySelector('a.icon.people')?.click();
    } else {
      document.querySelector('a.icon.location')?.click();
    }
  }
}

export default Filters;
