class Card {
  getHtml({zipName}, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');
    text.value = 'To confirm your identity, please provide a valid credit card. (Your card will be authorized but NOT charged.)';
    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question', 'js-credit-card-question');
    text.before(wrapper);
    wrapper.append(text);

    text.insertAdjacentHTML('afterend', `<br><small>(Prospect must provide full name, street address, ${zipName}, card number and expiration date. We share name, address and ${zipName} with you. If you need additional details on a specific prospect, please contact us.)</small>`);
    text.insertAdjacentHTML('afterend', '<input type="hidden" name="question[][answer_required]" value="true">');

    const answRequired = questionDiv.querySelector('[data-binding="answer_required"]');
    answRequired.value = 'true';
    answRequired.setAttribute('disabled', 'disabled');

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    const el = wrapper.querySelector('.js-credit-card-question').closest('.question-item').querySelector('[data-binding="answer_required"]');
    el.value = 'true';
    el.setAttribute('disabled', 'disabled');
    this.bindEvents();
  }

  isSingleQuestion() {
    return true;
  }
}

window.Card = Card;
