import QuestionnaireUtils from './utils';

class Questionaire {
  #Q_i = 0;

  nextQuestionNumber() {
    let current_max = 0;
    document.querySelectorAll('input.sequence_input,.new_sequence_input').forEach(function(el){
      if (!isNaN(el.value)) {
        current_max= Math.max(current_max,el.value);
      }
    });
    return  (current_max <= 0) ? 1 : current_max + 1;
  }

  getClassName(questionType) {
    return questionType.toLowerCase().split('/')
      .map(snake => snake.split('_')
        .map(substr => substr.charAt(0)
          .toUpperCase() +
          substr.slice(1))
        .join(''))
      .join('/');
  }

  createInstanceOf(C, args) {
    return new window[C](...args);
  }

  getQuestionHtml(question, data, questionDiv) {
    return question.getHtml(data, questionDiv);
  }

  removeNewQuestion(id, data){
    let question = document.querySelector('#'+id);
    let question_type = question.querySelector('[data-binding="question_type"]').value;
    LayoutUtils.show(document.querySelector('.edit-questions-add[rel="' + question_type + '"]'));

    question.remove();
    if ( +data.questionsLimit && document.querySelectorAll('.question-item').length < +data.questionsLimit ) {
      document.querySelector('.question-add-buttons-container').classList.remove('hidden-important');
    }
  }

  createQuestion(question, questionType, data) {
    if(document.querySelector('#account_questionnaire_enabled').value === 'false') {
      const field = document.querySelector('#account_questionnaire_enabled');
      field.value = 'true';
      field.dispatchEvent(new Event('change'));
    }

    var questionDiv = document.createElement('div');
    questionDiv.innerHTML = document.querySelector('#question').innerHTML;
    questionDiv.setAttribute('id' , 'q_'+this.#Q_i);
    questionDiv.setAttribute('class' , 'new-question question-item');
    questionDiv.querySelector('[data-binding="question_type"]').value = questionType;
    questionDiv.querySelector('[data-binding="sequence"]').value = this.nextQuestionNumber();
    questionDiv.querySelector('[name="question[][parent_text]"]').value = 'q_'+this.#Q_i; // for finding correct parent

    let toggleWrapper = questionDiv.querySelector('.listing-groups-toggle-wrapper');
    if (toggleWrapper) {
      toggleWrapper.querySelector('input.enabled-for-listing-groups').setAttribute('id', 'enabled_for_listing_groups_' + this.#Q_i);
    }

    let groupsWrapper = questionDiv.querySelector('.listing-groups-wrapper');
    if (groupsWrapper) {
      groupsWrapper.setAttribute('id', 'listing_groups_wrapper_' + this.#Q_i);
      groupsWrapper.querySelector('.custom-chosen-choices').setAttribute('id', 'listing_groups_chosen_' + this.#Q_i);
      groupsWrapper.querySelector('.listing-groups-select').setAttribute('data-question-id', this.#Q_i);
      groupsWrapper.querySelector('.listing-groups-included-excluded').setAttribute('id', 'included_for_listing_groups_' + this.#Q_i);
    }

    this.getQuestionHtml(question, data, questionDiv);

    const indx = this.#Q_i;

    LayoutUtils.show(questionDiv);
    questionDiv.querySelector('[data-binding="delete_link"]').addEventListener('click', () => this.removeNewQuestion('q_'+indx, data));

    this.#Q_i ++;

    return questionDiv;
  }

  cleanFieldsWithError() {
    document.querySelectorAll('.ts-wrapper').forEach((el) => {
      el.classList.remove('with-error');
    });
  }

  validateListingGroups() {
    var questionDiv;
    var questionWithMissingGroupsCount = 0;

    document.querySelectorAll('.enabled-for-listing-groups:checked').forEach(function(el) {
      questionDiv = el.closest('.question-item');
      let selectVal = questionDiv.querySelector('.listing-groups-select').value;
      if (!selectVal || selectVal.length == 0) {
        questionDiv.querySelector('.ts-wrapper').classList.add('with-error');
        questionWithMissingGroupsCount = questionWithMissingGroupsCount + 1;
      }
    });

    if (questionWithMissingGroupsCount > 0) {
      var pluralQuestions;
      if (questionWithMissingGroupsCount > 1) {
        pluralQuestions = 'are ' + questionWithMissingGroupsCount + ' questions';
      } else {
        pluralQuestions = 'is ' + questionWithMissingGroupsCount + ' question';
      }

      let content = document.querySelector('#listing_groups_missing_alert_popup').innerHTML;
      content = content.replace(':plural_questions', pluralQuestions);
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content: content}}));

      return false;
    }

    return true;
  }

  editQuestionnaire(url) {
    fetch(url, {headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }}).then(FetchUtils.checkResponseStatus)
    .then((resp) => {
      return resp.json();
    }).then(json => {
      document.querySelector('#questionnaire_holder').innerHTML = json.html;

      LayoutUtils.hide(document.querySelectorAll('.add-questionnaire, .add-questionnaire-container'));

      document.querySelector('#screening_question').dispatchEvent(new CustomEvent('html-changed'));
      document.dispatchEvent(new CustomEvent('html-changed', {detail: {container: '#questionnaire_holder'}}));
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  getQuestionnaires(url) {
    fetch(url, {headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }}).then(FetchUtils.checkResponseStatus)
    .then((resp) => {
      return resp.json();
    }).then(json => {
      document.querySelector('#screening_question').innerHTML = json.html;
      document.querySelector('#screening_question').dispatchEvent(new CustomEvent('html-changed'));
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  bindFormEvents(data) {
    document.querySelector('.js-questionnaire-form')?.addEventListener('submit', (e) => {
      this.cleanFieldsWithError();
      if (!this.validateListingGroups()) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    });

    document.querySelector('#questionnaire_holder form')?.addEventListener('cancelled', () => {
      document.querySelector('#questionnaire_holder').innerHTML = '';
      this.getQuestionnaires(data.accountQuestionnairesPath);
    });

    document.querySelector('#screening_question select#account_questionnaire_enabled').addEventListener('change', function(e){
      if(e.target.value == 'true'){
        LayoutUtils.hide(document.querySelectorAll('.questions-show'));
        document.querySelector('div#questions-wrap').style.display = 'block';
      }
      else {
        document.querySelector('div#questions-wrap').style.display = 'none';
      }
    });

    document.querySelectorAll('#screening_question .edit-questions-add').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        const q = e.target;
        let quesiton_type = q.getAttribute('rel');
        const questionInst = this.createInstanceOf(this.getClassName(quesiton_type), [data]);
        let allow_multiple = q.dataset.multiple === 'true';
        if (questionInst.isSingleQuestion() && !allow_multiple) {
          LayoutUtils.hide(q);
        }

        let question = this.createQuestion(questionInst, quesiton_type, data);

        q.closest('form').querySelector('.questions_container').append(question);
        if ( +data.questionsLimit && document.querySelectorAll('.question-item').length >= +data.questionsLimit ) {
          document.querySelector('.question-add-buttons-container').classList.add('hidden-important');
        }

        questionInst.init(question, data);
        const id = question.id;

        let questionId = id.split('_')[1];
        this.initializeSwitches(`#${id} .switchery-checkbox#enabled_for_listing_groups_${questionId}`);
        this.bindToggleChangeHandler(`#${id} #enabled_for_listing_groups_${questionId}`);

        return false;
      });
    });
  }

  bindEvents(data) {
    document.querySelector('.add-questionnaire')?.addEventListener('click', () => {
      let tpl = document.querySelector('#questionnaire-template').innerHTML;
      document.querySelector('#questionnaire_holder').innerHTML = tpl;
      document.querySelector('#questionnaire_holder form')?.classList.remove('partialform-inited');
      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#questionnaire_holder'}}));
      document.querySelector('#questionnaire_holder form').dispatchEvent(new CustomEvent('partial-form:enable'));
      document.querySelector('#questionnaire_holder').addEventListener('cancelled', () => {
        document.querySelector('#questionnaire_holder').innerHTML = '';
        this.getQuestionnaires(data.accountQuestionnairesPath);
      });

      this.bindFormEvents(data);

      LayoutUtils.hide(document.querySelectorAll('.add-questionnaire, .add-questionnaire-container'));
    });

    document.querySelectorAll('.js-delete-question').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        QuestionnaireUtils.removeQuestions(btn.href);
        return false;
      });
    });

    document.querySelectorAll('.js-edit-questionnaire').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        this.editQuestionnaire(btn.href);
        return false;
      });
    });

    document.querySelectorAll('.js-make-default-questionnaire').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const btn = e.target;
        btn.classList.add('disabled')
        fetch(btn.href, {method: 'PUT', headers: {
          'X-CSRF-Token': FetchUtils.CSRFToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }}).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          document.querySelector('#screening_question').innerHTML = json.html;

          document.querySelector('#screening_question').dispatchEvent(new CustomEvent('html-changed'));
        }).catch((err) => {
          FetchUtils.handleResponseError(err);
          btn.classList.remove('disabled')
        });
        return false;
      });
    });

    document.querySelectorAll('.js-delete-questionnaire').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        fetch(e.target.href, {method: 'DELETE', headers: {
          'X-CSRF-Token': FetchUtils.CSRFToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }}).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          if (json.success) {
            document.querySelector(`#questionnaire_${json.id}`).remove();
          }
        }).catch((err) => {
          FetchUtils.handleResponseError(err);
        });
        return false;
      });
    });
  }

  initializeSwitches(selector) {
    const initializeToggleTooltip = this.initializeToggleTooltip;
    document.querySelectorAll(selector).forEach(function(item) {
      document.dispatchEvent(new CustomEvent('sm-toggle:init', {detail: {el: selector}}));
      initializeToggleTooltip(item.parentElement.querySelector('span small'));
    });
  }

  initializeToggleTooltip(smallEl) {
    if (smallEl) {
      smallEl.innerHTML = 'IF';

      tippy(smallEl, {
        allowHTML: true,
        content: '<div>Include based on listing groups</div>',
        theme: 'default'
      });
    }
  }

  bindToggleChangeHandler(selector) {
    document.querySelectorAll(selector).forEach((item) => {
      item.addEventListener('change', function(e) {
        let checked = e.target.checked;
        let groupsWrapper = e.target.closest('.question-item').querySelector('.listing-groups-wrapper');
        let hiddenFlag = e.target.parentElement.querySelector('input[type="hidden"]');

        if (checked) {
          LayoutUtils.show(groupsWrapper);
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: `#${groupsWrapper.id}`}}));
          hiddenFlag.setAttribute('disabled', 'disabled');
        } else {
          LayoutUtils.hide(groupsWrapper);
          hiddenFlag.removeAttribute('disabled');
        }
      });
    });
  }

  init(data) {
    if (!document.querySelector('.edit_questionnaire.enabled')) {
      LayoutUtils.show(document.querySelectorAll('.add-questionnaire, .add-questionnaire-container'));
    } else {
      document.querySelector('#questionnaire_holder form').dispatchEvent(new CustomEvent('partial-form:enable'));
      this.bindFormEvents(data);
    }

    document.querySelectorAll('.js-question-item').forEach(q => {
      const questionInst = this.createInstanceOf(this.getClassName(q.dataset.type), [data]);
      questionInst.init(q, data, {questionInfo: q.dataset});
    });

    this.initializeSwitches('.questions_container .switchery-checkbox');
    this.bindToggleChangeHandler('.questions_container .enabled-for-listing-groups');

    this.bindEvents(data);
    if (document.querySelector('.js-questionnaire-form')) {
      this.bindFormEvents(data);
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const qh = document.getElementById('questionnaire_holder');
  if (qh) {
    new Questionaire().init(qh.dataset);
  }

  const sq = document.querySelector('#screening_question');
  if (sq) {
    sq.addEventListener('html-changed', () => {
      const qh = document.getElementById('questionnaire_holder');
      if (qh) {
        new Questionaire().init(qh.dataset);
      }

      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#questionnaire_holder'}}));
    });
  }
});
