class DeviceUtils {
  static getViewportWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  }

  static isMobileViewport() {
    return DeviceUtils.getViewportWidth() < 710;
  }

  static showCanadian() {
    document.querySelector('.canadian-true').classList.remove('hidden');
    document.querySelector('.canadian-true').removeAttribute('disabled');
    document.querySelector('.canadian-false').classList.add('hidden');
    document.querySelector('.canadian-false').setAttribute('disabled', 'disabled');
  }

  static hideCanadian() {
    document.querySelector('.canadian-true').classList.add('hidden');
    document.querySelector('.canadian-true').setAttribute('disabled', 'disabled');
    document.querySelector('.canadian-false').classList.remove('hidden');
    document.querySelector('.canadian-false').removeAttribute('disabled');
  }

  static showCodeboxNumberZone() {
    const divCodeboxNumberZone = document.querySelector('.js-codebox-number-and-zone');
    LayoutUtils.show(divCodeboxNumberZone);
  }

  static hideCodeboxNumberZone() {
    const divCodeboxNumberZone = document.querySelector('.js-codebox-number-and-zone');
    LayoutUtils.hide(divCodeboxNumberZone);
  }

  static getOrderPageData() {
    const orderPage = document.querySelector('#codebox-order');
    return orderPage.dataset;
  }

  static setDescriptionLinesHeigth() {
    if (document.querySelector('.js-device-features')) {
      if (!DeviceUtils.isMobileViewport()) {
        const wrapper = document.querySelector('.js-devices:not(.hidden)');
        const firstColumn = wrapper.querySelector('.js-device-features');
        const features = firstColumn.querySelectorAll('.js-feature-description');

        features.forEach(feature => {
          // Get index of row
          const index = Array.from(features).indexOf(feature);
          //Get maximum height for current row
          let maxHeight = 0;
          wrapper.querySelectorAll('.js-device-features').forEach(featureColumn => {
            const cell = featureColumn.querySelectorAll('.js-feature-description').item(index);
            if (cell) {
              maxHeight = Math.max(maxHeight, cell.querySelector('p').offsetHeight);
            }

          });
          //Set height for all columns of index row equals maximum
          wrapper.querySelectorAll('.js-device-features').forEach(featureColumn => {
            const cell = featureColumn.querySelectorAll('.js-feature-description').item(index);
            if (cell) {
              cell.style.height = `${maxHeight}px`;
            }
          });
        });
      } else {
        document.querySelectorAll('.js-device-features .js-feature-description').forEach(el => {
          el.style.height = 'auto';
        });
      }
    }
  }
}

export default DeviceUtils;