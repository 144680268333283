class Movedate {
  getHtml({movedateQuestionText}, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');
    text.value = 'What\'s your earliest possible move-in date?';

    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);
    text.insertAdjacentHTML('afterend', movedateQuestionText);

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return true;
  }
}

window.Movedate = Movedate;
